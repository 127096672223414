import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import { FaStar } from "react-icons/fa"
import BrokerSearch from "../components/BrokerSearch"

export default () => (
  <Layout>
    <main>
      <section
        className="section section-xl bg-primary overlay-dark text-white rounded"
        style={{ backgroundImage: `url("hero.jpg")` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-md-center">
              <h1 className="display-2">
                Find the perfect{" "}
                <span className="font-weight-bolder">broker</span> for you.
              </h1>
              <p className="lead text-muted mt-4">
                <span className="font-weight-bold">12,000+</span> professional
                brokers across Australia.
                <br />
                Discover the right one for you.
              </p>
            </div>
          </div>
          <BrokerSearch />
        </div>
      </section>
      <section className="section pb-lg-6 pb-5">
        <div className="container">
          <div className="row mt-6">
            <div className="col-md-4">
              <h2 className="h1 mb-5">
                Our brokers are the{" "}
                <span className="font-weight-bold">top brokers</span> <br />
                of the industry.
              </h2>
            </div>
            <div className="col-md-4">
              <p className="lead">
                The right broker is not only about finding someone who will
                search and source the best home loan for you but about building
                a strong, ongoing partnership.
              </p>
            </div>
            <div className="col-md-4">
              <p className="lead">
                Our algorithm combines reviews, tenure in industry, peer
                recognition and skill set as a few of our many key factors that
                help determine our recommended{" "}
                <span className="font-weight-bold">topbrokers</span>.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section bg-soft">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-9 text-center">
              <div className="nav-wrapper">
                <ul
                  className="nav nav-pills testimonial-nav nav-pill-circle flex-sm-row justify-content-center"
                  id="tab-34"
                  // role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link text-sm-center avatar-link active"
                      id="tab-link-example-13"
                      data-toggle="tab"
                      href="#link-example-13"
                      role="tab"
                      aria-controls="link-example-13"
                      aria-selected="true"
                    >
                      <img
                        className="rounded-circle"
                        src="/profile-image-1.jpg"
                        alt="Description"
                      ></img>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-sm-center avatar-link"
                      id="tab-link-example-14"
                      data-toggle="tab"
                      href="#link-example-14"
                      role="tab"
                      aria-controls="link-example-14"
                      aria-selected="false"
                    >
                      <img
                        className="rounded-circle"
                        src="/profile-image-2.jpg"
                        alt="Description"
                      ></img>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-sm-center avatar-link"
                      id="tab-link-example-15"
                      data-toggle="tab"
                      href="#link-example-15"
                      role="tab"
                      aria-controls="link-example-15"
                      aria-selected="false"
                    >
                      <img
                        className="rounded-circle"
                        src="/profile-image-3.jpg"
                        alt=" Description"
                      ></img>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card border-0">
                <div className="card-body bg-soft p-0">
                  <div className="tab-content" id="tabcontentexample-5">
                    <div
                      className="tab-pane fade show active"
                      id="link-example-13"
                      role="tabpanel"
                      aria-labelledby="tab-link-example-13"
                    >
                      <span className="d-block my-3">
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                      </span>
                      <blockquote className="blockquote">
                        Add quote text here
                        <footer className="blockquote-footer mt-4 text-primary">
                          Oana Calota
                        </footer>
                      </blockquote>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="link-example-14"
                      role="tabpanel"
                      aria-labelledby="tab-link-example-14"
                    >
                      <span className="d-block my-3">
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                      </span>
                      <blockquote className="blockquote">
                        Add quote text here
                        <footer className="blockquote-footer mt-4 text-primary">
                          Zoltan Szőgyényi
                        </footer>
                      </blockquote>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="link-example-15"
                      role="tabpanel"
                      aria-labelledby="tab-link-example-15"
                    >
                      <span className="d-block my-3">
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                        <i className="star fas fa-star text-warning">
                          <FaStar />
                        </i>
                      </span>
                      <blockquote className="blockquote">
                        Add quote text here
                        <footer className="blockquote-footer mt-4 text-primary">
                          Tanislav Robert
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </Layout>
)
