export const defaultRegions = [
  {
    value: "ACT",
    label: "Anu, ACT 0200",
  },
  {
    value: "ACT",
    label: "Barton, ACT 0221",
  },
  {
    value: "NT",
    label: "Darwin, NT 0800",
  },
  {
    value: "NT",
    label: "Darwin, NT 0801",
  },
  {
    value: "NT",
    label: "Wagait Beach, NT 0803",
  },
  {
    value: "NT",
    label: "Parap, NT 0804",
  },
  {
    value: "NT",
    label: "Alawa, NT 0810",
  },
  {
    value: "NT",
    label: "Brinkin, NT 0810",
  },
  {
    value: "NT",
    label: "Casuarina, NT 0810",
  },
  {
    value: "NT",
    label: "Coconut Grove, NT 0810",
  },
  {
    value: "NT",
    label: "Jingili, NT 0810",
  },
  {
    value: "NT",
    label: "Lee Point, NT 0810",
  },
  {
    value: "NT",
    label: "Millner, NT 0810",
  },
  {
    value: "NT",
    label: "Moil, NT 0810",
  },
  {
    value: "NT",
    label: "Nakara, NT 0810",
  },
  {
    value: "NT",
    label: "Nightcliff, NT 0810",
  },
  {
    value: "NT",
    label: "Rapid Creek, NT 0810",
  },
  {
    value: "NT",
    label: "Tiwi, NT 0810",
  },
  {
    value: "NT",
    label: "Wagaman, NT 0810",
  },
  {
    value: "NT",
    label: "Wanguri, NT 0810",
  },
  {
    value: "NT",
    label: "Casuarina, NT 0811",
  },
  {
    value: "NT",
    label: "Anula, NT 0812",
  },
  {
    value: "NT",
    label: "Karama, NT 0812",
  },
  {
    value: "NT",
    label: "Leanyer, NT 0812",
  },
  {
    value: "NT",
    label: "Malak, NT 0812",
  },
  {
    value: "NT",
    label: "Marrara, NT 0812",
  },
  {
    value: "NT",
    label: "Northlakes, NT 0812",
  },
  {
    value: "NT",
    label: "Sanderson, NT 0812",
  },
  {
    value: "NT",
    label: "Woodleigh Gardens, NT 0812",
  },
  {
    value: "NT",
    label: "Wulagi, NT 0812",
  },
  {
    value: "NT",
    label: "Sanderson, NT 0813",
  },
  {
    value: "NT",
    label: "Nightcliff, NT 0814",
  },
  {
    value: "NT",
    label: "Charles Darwin University, NT 0815",
  },
  {
    value: "NT",
    label: "Bagot, NT 0820",
  },
  {
    value: "NT",
    label: "Bayview, NT 0820",
  },
  {
    value: "NT",
    label: "Charles Darwin, NT 0820",
  },
  {
    value: "NT",
    label: "Coonawarra, NT 0820",
  },
  {
    value: "NT",
    label: "Cullen Bay, NT 0820",
  },
  {
    value: "NT",
    label: "Darwin Dc, NT 0820",
  },
  {
    value: "NT",
    label: "Darwin International Airport, NT 0820",
  },
  {
    value: "NT",
    label: "Darwin Mc, NT 0820",
  },
  {
    value: "NT",
    label: "East Point, NT 0820",
  },
  {
    value: "NT",
    label: "Fannie Bay, NT 0820",
  },
  {
    value: "NT",
    label: "Larrakeyah, NT 0820",
  },
  {
    value: "NT",
    label: "Ludmilla, NT 0820",
  },
  {
    value: "NT",
    label: "Parap, NT 0820",
  },
  {
    value: "NT",
    label: "Raaf Base Darwin, NT 0820",
  },
  {
    value: "NT",
    label: "Stuart Park, NT 0820",
  },
  {
    value: "NT",
    label: "The Gardens, NT 0820",
  },
  {
    value: "NT",
    label: "The Narrows, NT 0820",
  },
  {
    value: "NT",
    label: "Winnellie, NT 0820",
  },
  {
    value: "NT",
    label: "Woolner, NT 0820",
  },
  {
    value: "NT",
    label: "Winnellie, NT 0821",
  },
  {
    value: "NT",
    label: "Acacia Hills, NT 0822",
  },
  {
    value: "NT",
    label: "Angurugu, NT 0822",
  },
  {
    value: "NT",
    label: "Annie River, NT 0822",
  },
  {
    value: "NT",
    label: "Bathurst Island, NT 0822",
  },
  {
    value: "NT",
    label: "Bees Creek, NT 0822",
  },
  {
    value: "NT",
    label: "Border Store, NT 0822",
  },
  {
    value: "NT",
    label: "Cox Peninsula, NT 0822",
  },
  {
    value: "NT",
    label: "Croker Island, NT 0822",
  },
  {
    value: "NT",
    label: "Daly River, NT 0822",
  },
  {
    value: "NT",
    label: "Darwin Mc, NT 0822",
  },
  {
    value: "NT",
    label: "Delissaville, NT 0822",
  },
  {
    value: "NT",
    label: "Fly Creek, NT 0822",
  },
  {
    value: "NT",
    label: "Galiwinku, NT 0822",
  },
  {
    value: "NT",
    label: "Goulbourn Island, NT 0822",
  },
  {
    value: "NT",
    label: "Gunn Point, NT 0822",
  },
]
