import React from "react"
import AsyncSelect from "react-select/async"
import { regions } from "../data/bigdata"
import { defaultRegions } from "../data/smalldata"
import { Button } from "react-bootstrap"
import { navigate } from "gatsby"

const filterRegions = inputValue => {
  return regions.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  )
}

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterRegions(inputValue))
    }, 1000)
  })

const BrokerSearch = class extends React.Component {
  state = {
    selectedOption: null,
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption })
  }

  handleClick() {
    var regionValue = ""
    // const label = this.state.selectedOption.label

    switch (this.state.selectedOption.value) {
      case "NSW":
        regionValue = "new-south-wales"
        break
      case "QLD":
        regionValue = "queensland"
        break
      case "SA":
        regionValue = "south-australia"
        break
      case "TAS":
        regionValue = "tasmania"
        break
      case "VIC":
        regionValue = "victoria"
        break
      case "WA":
        regionValue = "western-australia"
        break
      case "ACT":
        regionValue = "australian-capital-territory"
        break
      case "NT":
        regionValue = "northern-territory"
        break
      default:
        regionValue = "northern-territory"
        break
    }

    navigate("/" + regionValue)
  }

  render() {
    return (
      <div className="row mt-4 justify-content-center">
        <div className="col-lg-10">
          <div className="card card-body">
            <form
              autoComplete="off"
              className="row justify-content-center"
              method="get"
            >
              <div className="col-12 col-lg-8">
                <AsyncSelect
                  cacheOptions
                  className="new-form-control"
                  defaultOptions={defaultRegions}
                  loadOptions={promiseOptions}
                  onChange={this.handleChange}
                  placeholder="Search by suburb or postcode"
                />
                {/* <div className="form-group mb-lg-0">
                  <div className="input-group input-group-lg mb-lg-0">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                    </div>

                    <input
                      id="search-location"
                      type="text"
                      className="form-control autocomplete"
                      placeholder="Search by suburb or postcode"
                      tabIndex="1"
                      required
                    />
                  </div>
                </div> */}
              </div>

              <div className="col-12 col-lg-4">
                <Button
                  className="btn btn-lg btn-primary btn-block mt-3 mt-md-0 animate-up-2"
                  variant="outline-primary"
                  onClick={this.handleClick.bind(this)}
                >
                  Search
                </Button>
                {/* <button
                  className="btn btn-lg btn-primary btn-block mt-3 mt-md-0 animate-up-2"
                  type="submit"
                >
                  Search
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default BrokerSearch

//

// < Row >
// <Col>
//   <AsyncSelect
//     cacheOptions
//     defaultOptions={defaultRegions}
//     loadOptions={promiseOptions}
//     onChange={this.handleChange}
//   />
// </Col>
// <Col md="auto">
//   <Button
//     variant="outline-primary"
//     onClick={this.handleClick.bind(this)}
//   >
//     Search
//         </Button>
// </Col>
//     </Row >
